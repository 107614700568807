export default function initVideo() {
    const videos = document.querySelectorAll('video');
    const videoPlays = document.querySelectorAll('.js-video-play');

    if (videos.length === videoPlays.length) {
        for (let i = 0; i < videos.length; ++i) {
            const video = videos[i];
            const playButton = videoPlays[i];

            playButton.addEventListener('click', function () {
                this.classList.add('-hide');

                video.play();

                video.controls = true;
            });

            video.addEventListener('ended', function () {
                playButton.classList.remove('-hide');
                video.controls = false;
            });

            video.addEventListener('pause', function () {
                playButton.classList.remove('-hide');
                video.controls = false;
            });
        }
    }
}

export default function activeProduct() {
    const productTypes = document.querySelectorAll('.js-active-product');
    const productLink = document.querySelector('.js-product-link');

    productTypes.forEach(function (productType) {
        productType.addEventListener('click', function () {
            productTypes.forEach(function (type) {
                type.classList.remove('-active');
            });

            const urlProduct = this.dataset.url;
            productLink.setAttribute('href', urlProduct);
            this.classList.add('-active');
        });
    });
}

export default function burger() {
    const h = document.getElementById('js-header');
    const activeClass = 'is-active';
    const noScrollClass = 'is-no-scroll';
    const rwdTriggers = document.querySelectorAll('.js-rwd-trigger');
    const html = document.documentElement;

    /**
     * close rwd dropdown
     */
    rwdTriggers.forEach(rwdTrigger => {
        rwdTrigger.addEventListener(
            'mousedown',
            event => {
                event.preventDefault();
                const target = rwdTrigger.dataset.target;
                const target_elem = document.getElementById(target);
                if (rwdTrigger.classList.contains(activeClass)) {
                    html.classList.remove(noScrollClass);
                    rwdTrigger.classList.remove(activeClass);
                    h.classList.remove(activeClass);
                    target_elem.classList.remove(activeClass);
                } else {
                    html.classList.add(noScrollClass);
                    target_elem.classList.add(activeClass);
                    rwdTrigger.classList.add(activeClass);
                    h.classList.add(activeClass);
                }
            },
            false
        );
    });

    const menuItemHasChildren = document.querySelector(
        '.mobile-nav .menu-item-has-children'
    );

    if (menuItemHasChildren) {
        menuItemHasChildren.addEventListener('click', function (event) {
            menuItemHasChildren.classList.toggle('-active');
        });
    }
}

/**
 * Page Header Sticky block script
 */
export default function pageHeaderSticky() {
    /**
     * shrink header on scroll
     */
    const header = document.getElementById('js-header');
    const headerHeight = header.offsetHeight;
    let scrollDirection = null;
    let lastScrollTop = 0;

    document.addEventListener('scroll', event => {
        var st = window.pageYOffset || document.documentElement.scrollTop;

        if (!header.classList.contains('is-active')) {
            //scroll strength
            if (Math.abs(st - lastScrollTop) < 15) {
                return;
            }

            //scroll direction
            if (st > lastScrollTop) {
                header.classList.remove('is-sticky');
                removeDisabled();
            } else {
                header.classList.add('is-sticky');
                removeDisabled();
            }

            //scrolled
            if (window.scrollY > headerHeight + 20) {
                header.classList.add('is-scrolled');
                removeDisabled();
            } else {
                header.classList.remove('is-scrolled');
                removeDisabled();
            }

            //fixed
            if (window.scrollY > headerHeight + 20) {
                header.classList.add('is-fixed');
            } else if (window.scrollY == 0) {
                header.classList.remove('is-fixed');
            }
        }
        if (window.scrollY == 0) {
            header.classList.remove('is-fixed', 'is-scrolled', 'is-sticky');
        }

        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    });

    function removeDisabled() {
        let timeoutHandle = null;
        if (timeoutHandle) {
            window.clearTimeout(timeoutHandle);
            let timeoutHandle = null;
        }
        header.classList.add('is-disabled');
        timeoutHandle = window.setTimeout(function () {
            header.classList.remove('is-disabled');
        }, 200);
    }
}

export default function activeAccordion() {
    const accordionBtns = document.querySelectorAll('.js-active-accordion');

    accordionBtns.forEach(function (accordionBtn) {
        accordionBtn.addEventListener('click', function () {
            const parentAccordionItem = accordionBtn.closest('.accordion-item');

            if (parentAccordionItem.classList.contains('-active')) {
                parentAccordionItem.classList.remove('-active');
                return;
            }

            document.querySelectorAll('.accordion-item').forEach(item => {
                item.classList.remove('-active');
            });

            parentAccordionItem.classList.add('-active');
        });
    });
}

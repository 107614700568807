import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default function videoSlider() {
    const videoSlider = document.querySelector('#js-video-slider');

    const sliderVideo = new Swiper(videoSlider, {
        slidesPerView: 1,
        spaceBetween: 16,
        modules: [Navigation],
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

document.addEventListener('DOMContentLoaded', function () {
    let videoSliderContainer = document.querySelector('#js-video-slider');
    let prevButton = videoSliderContainer.querySelector('.swiper-button-prev');
    let nextButton = videoSliderContainer.querySelector('.swiper-button-next');

    function stopAllVideos() {
        if (videoSliderContainer) {
            let iframes = videoSliderContainer.querySelectorAll(
                '.video-slider__iframe'
            );
            iframes.forEach(function (iframe) {
                let src = iframe.src;
                iframe.src = src;
            });
        }
    }

    if (prevButton && nextButton) {
        prevButton.addEventListener('click', stopAllVideos);
        nextButton.addEventListener('click', stopAllVideos);
    }
});

export default function productTab() {
    const descriptionTab = document.getElementById('tab-description');
    const opinionsTab = document.getElementById('tab-opinions');
    const descriptionDiv = document.getElementById('description');
    const opinionsDiv = document.getElementById('opinions');

    const showOpinion = document.querySelector('.js-show-opinion');

    if (showOpinion) {
        showOpinion.addEventListener('click', function () {
            descriptionTab.classList.remove('tab__button--active');
            opinionsTab.classList.add('tab__button--active');

            descriptionDiv.classList.remove('tab__active');
            opinionsDiv.classList.add('tab__active');

            const sliderOpinion = document.querySelector('.js-slider-opinion');
            if (sliderOpinion) {
                const offset = -100;
                const topPos =
                    sliderOpinion.getBoundingClientRect().top +
                    window.pageYOffset +
                    offset;
                window.scrollTo({ top: topPos, behavior: 'smooth' });
            }
        });
    }

    if (descriptionTab) {
        descriptionTab.addEventListener('click', function () {
            descriptionTab.classList.add('tab__button--active');
            opinionsTab.classList.remove('tab__button--active');

            descriptionDiv.classList.add('tab__active');
            opinionsDiv.classList.remove('tab__active');
        });
    }

    if (opinionsTab) {
        opinionsTab.addEventListener('click', function () {
            opinionsTab.classList.add('tab__button--active');
            descriptionTab.classList.remove('tab__button--active');

            descriptionDiv.classList.remove('tab__active');
            opinionsDiv.classList.add('tab__active');
        });
    }
}

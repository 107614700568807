export default function showCookieBanner() {
    function addEvent(event, selector, callback, context) {
        document.addEventListener(event, e => {
            if (e.target.closest(selector)) {
                callback(e);
            }
        });
    }
    addEvent('click', '.js-show-banner', function (e) {
        e.preventDefault();
        document.querySelectorAll('.cmplz-manage-consent').forEach(obj => {
            obj.click();
        });
        document
            .querySelector('.cmplz-body .cmplz-categories')
            .classList.add('cmplz-fade-in');
    });
}

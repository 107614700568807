export default function opinion() {
    let products = document.querySelectorAll('.js-active');

    const button = document.querySelector('.js-button-opinion');

    let stars = document.querySelectorAll('.form__star[data-id]');
    let number = document.querySelector('.form__number');
    let form = $('.js-opinion-form');

    products.forEach(function (product) {
        product.addEventListener('click', function () {
            let clickedId = parseInt(this.getAttribute('data-id'));
            products.forEach(function (product) {
                let productId = parseInt(product.getAttribute('data-id'));
                if (productId == clickedId) {
                    product.classList.add('-active');
                    document.querySelector('input[name="product_id"]').value =
                        clickedId;
                } else {
                    product.classList.remove('-active');
                }
            });
        });
    });

    if (button) {
        button.addEventListener('click', function () {
            if (
                document.querySelector('input[name="product_id"]').value == ''
            ) {
                $('.form__errorButton').addClass('form__errorButton--active');
                $('.form__errorButton').text('Proszę wybrać produkt');
            } else {
                $('.form__errorButton').removeClass(
                    'form__errorButton--active'
                );
                form.removeClass('form__hidden');
            }
        });
    }

    stars.forEach(function (star) {
        star.addEventListener('click', function () {
            let clickedId = parseInt(this.getAttribute('data-id'));
            number.textContent = clickedId + '/5';
            stars.forEach(function (star) {
                let starId = parseInt(star.getAttribute('data-id'));
                if (starId <= clickedId) {
                    star.classList.add('form__star--active');
                } else {
                    star.classList.remove('form__star--active');
                }
            });
        });
    });

    $ = jQuery;

    $('.js-opinion-form').on('submit', function (event) {
        event.preventDefault();
        let checkedStars = $('input[name="star"]:checked');
        let name = $('input[name="name"]').val().trim();
        let form = $(this);

        let productId = $('input[name="product_id"]').val().trim();
        let cookieName = 'opinion-product-' + productId;
        let cookieExists = document.cookie
            .split(';')
            .some(cookie => cookie.trim().startsWith(cookieName + '='));

        if (cookieExists) {
            $('.form__error').text(
                'Opinia dla tego produktu została już dodana'
            );
            $('.form__error').addClass('form__error--active');
            return;
        } else {
            $('.form__error').removeClass('form__error--active');
        }

        if (name === '') {
            $('.form__errorName').addClass('form__errorName--active');
            $('.form__errorName').text('Proszę dodać nazwę');
            $('.form__error').text('W formularzu wystąpił problem');
            $('.form__error').addClass('form__error--active');
        } else {
            $('.form__errorName').removeClass('form__errorName--active');
        }

        if (checkedStars.length === 0) {
            $('.form__errorStar').addClass('form__errorStar--active');
            $('.form__errorStar').text('Proszę dodać ocenę');
            $('.form__error').text('W formularzu wystąpił problem');
            $('.form__error').addClass('form__error--active');
        } else {
            $('.form__errorStar').removeClass('form__errorStar--active');
        }

        if (name !== '' && checkedStars.length !== 0) {
            const recaptchaSiteKey = document
                .querySelector('.js-opinion-form')
                .getAttribute('data-site-key');
            grecaptcha.ready(function () {
                grecaptcha
                    .execute(recaptchaSiteKey, { action: 'submit' })
                    .then(function (token) {
                        if (!token) {
                            $('.form__error').text(
                                'Nieprawidłowy token reCAPTCHA. Spróbuj ponownie.'
                            );
                            $('.form__error').addClass('form__error--active');
                            return;
                        }

                        let formData = form.serialize();
                        $.ajax({
                            type: 'post',
                            url: ajax.url,
                            data: {
                                formData: formData,
                                action: 'active',
                                token: token,
                            },
                            success: function (response) {
                                console.log(response);
                                form[0].reset();
                                stars.forEach(function (star) {
                                    star.classList.remove('form__star--active');
                                });
                                // $(".form__error").removeClass('form__error--active');
                                $('.form__error').addClass(
                                    'form__error--green'
                                );
                                $('.form__error').text('Wysłano pomyślnie');
                            },
                            error: function (err) {
                                console.log(err);
                            },
                        });
                    });
            });
        }
    });
}

export default function megaMenu() {
    const navItems = document.querySelectorAll('.js-mega-menu');
    const megaMenus = document.querySelectorAll('.mega-menu');
    const menuItemHasChildren = document.querySelector(
        '.menu .menu-item-has-children'
    );
    let menuHideTimeouts = [];

    navItems.forEach(item => {
        item.addEventListener('mouseover', function () {
            if (window.innerWidth > 1199) {
                menuHideTimeouts.forEach(clearTimeout);

                const menuToOpen = document.getElementById(
                    item.getAttribute('data-attribute')
                );

                megaMenus.forEach(menu => {
                    if (menu !== menuToOpen) {
                        menuItemHasChildren.classList.remove('-active');

                        menu.style.transform = 'scaleY(0)';
                        let timeoutId = setTimeout(() => {
                            menu.style.display = 'none';
                        }, 300);
                        menuHideTimeouts.push(timeoutId);
                    }
                });

                if (menuToOpen) {
                    if (
                        item.getAttribute('data-attribute') ==
                        'mega-menu-products'
                    ) {
                        menuItemHasChildren.classList.add('-active');
                    }

                    menuToOpen.style.display = 'block';
                    setTimeout(() => {
                        menuToOpen.style.transform = 'scaleY(1)';
                    }, 50);
                }
            }
        });

        item.addEventListener('click', function () {
            if (window.innerWidth <= 1199) {
                const menuToToggle = document.getElementById(
                    item.getAttribute('data-attribute')
                );
                if (menuToToggle.style.transform === 'scaleY(1)') {
                    menuToToggle.style.transform = 'scaleY(0)';
                    let timeoutId = setTimeout(() => {
                        menuToToggle.style.display = 'none';
                    }, 300);
                    menuHideTimeouts.push(timeoutId);
                } else {
                    menuToToggle.style.display = 'block';
                    setTimeout(() => {
                        menuToToggle.style.transform = 'scaleY(1)';
                    }, 50);
                }
            }
        });
    });

    megaMenus.forEach(menu => {
        menu.addEventListener('mouseout', function (event) {
            if (
                !event.relatedTarget ||
                (!event.relatedTarget.closest('.mega-menu') &&
                    !Array.from(navItems).includes(event.relatedTarget))
            ) {
                menuItemHasChildren.classList.remove('-active');
                menu.style.transform = 'scaleY(0)';
                let timeoutId = setTimeout(() => {
                    menu.style.display = 'none';
                }, 300);
                menuHideTimeouts.push(timeoutId);
            }
        });
    });

    let isClickInsideMenu = false;

    navItems.forEach(item => {
        item.addEventListener('click', () => {
            isClickInsideMenu = true;
        });
    });

    megaMenus.forEach(menu => {
        menu.addEventListener('click', () => {
            isClickInsideMenu = true;
        });
    });

    document.addEventListener('click', function (event) {
        if (window.innerWidth <= 1200) {
            if (!isClickInsideMenu) {
                megaMenus.forEach(menu => {
                    menu.style.transform = 'scaleY(0)';
                    let timeoutId = setTimeout(() => {
                        menu.style.display = 'none';
                    }, 300);
                    menuHideTimeouts.push(timeoutId);
                });
            }
            isClickInsideMenu = false;
        }
    });
}

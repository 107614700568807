export default function showProduct() {
    let urlParams = new URLSearchParams(window.location.search);
    let productId = urlParams.get('product');
    let form = $('.js-opinion-form');

    if (productId) {
        const showOpinion = document.querySelectorAll('.js-active');

        showOpinion.forEach(element => {
            element.classList.remove('-active');
            if (element.getAttribute('data-id') === productId) {
                element.classList.add('-active');
                document.querySelector('input[name="product_id"]').value =
                    productId;
                $('.form__errorButton').removeClass(
                    'form__errorButton--active'
                );
                form.removeClass('form__hidden');
            }
        });
    }
}

import Aos from 'aos';
import { Modal } from 'bootstrap';
import activeAccordion from './components/active-accordion';
import activeProduct from './components/active-product';
import burger from './components/burger';
import megaMenu from './components/mega-menu';
import opinions from './components/opinion';
import pageHeaderSticky from './components/page-header-sticky';
import showCookieBanner from './components/show-cookie-banner';
import showProduct from './components/show-product';
import sliders from './components/swiper';
import productTab from './components/tab-product';
import initVideo from './components/video';
import videoSlider from './components/video-slider';

var documentReady = function () {
    megaMenu();
    activeAccordion();
    initVideo();
    activeProduct();
    burger();
    pageHeaderSticky();
    sliders();
    showCookieBanner();
    opinions();
    productTab();
    showProduct();
    videoSlider();
    Modal;

    Aos.init({
        once: true,
    });
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}

import Swiper from 'swiper';
import { Autoplay, Navigation, Scrollbar } from 'swiper/modules';

export default function sliders() {
    const sliders = document.querySelectorAll('.js-slider-mobile');

    sliders.forEach(el => {
        const slider = new Swiper(el, {
            modules: [Scrollbar],
            slidesPerView: 1.1,
            spaceBetween: 16,
            breakpoints: {
                960: {
                    slidesPerView: 2,
                },
            },
            scrollbar: {
                el: '.swiper-scrollbar',
            },
        });
    });

    const megaMenuSlider = document.querySelector('.js-mega-menu-slider');

    const sliderMegaMenu = new Swiper(megaMenuSlider, {
        slidesPerView: 5,
        spaceBetween: 16,
        modules: [Navigation],
        loop: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            2500: {
                slidesPerView: 5,
            },
            1450: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 3,
            },
            960: {
                slidesPerView: 2,
            },
            368: {
                slidesPerView: 1.2,
            },
        },
    });

    const opinionsSlider = document.querySelectorAll('.js-slider-opinion');

    opinionsSlider.forEach(el => {
        const slider = new Swiper(el, {
            slidesPerView: 1.1,
            spaceBetween: 16,
            modules: [Autoplay],
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                960: {
                    slidesPerView: 2.2,
                },
                1200: {
                    slidesPerView: 3.5,
                },
                1450: {
                    slidesPerView: 4,
                },
            },
        });
    });
}
